import React from 'react';
import { Container } from 'react-bootstrap';
import Layout from '../components/layout';

class NotFound extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <Layout title={"Not Found"}>
        <Container style={{ textAlign: 'center' }}>
          <img
            src={'/404image.png'}
            width={'250'}
            height={'350'}
            alt='404 not found' />
        </Container>
      </Layout>
    );
  }
}

export default NotFound;
